export default {
  委托订票单: '委託訂票單',
  基本信息: '基本信息',
  交易号码: '交易號碼',
  电子单据: '電子單據',
  客户: '客户',
  交易时间: '交易時間',
  交易日期: '交易日期',
  联络人中文名: '聯絡人中文名',
  联络人英文名: '聯絡人英文名',
  联络人姓名: '聯絡人姓名',
  联络人电邮: '聯絡人電郵',
  联络人电话: '聯絡人電話',
  联络人传真: '聯絡人傳真',
  销售员: '銷售員',
  销售员电邮: '銷售員電郵',
  销售员电话: '銷售員電話',
  销售员传真: '銷售員傳真',
  单据模板: '單據模板',
  付款方式: '付款方式',
  付款说明: '付款說明',
  客户回复期限: '客戶回復期限',
  客户回复期限提示: '請聯系{0}，確認客戶回復期限前可給客戶保留位子',
  订单备注: '訂單備註',
  订购须知: '訂購須知',
  签字盖章件: '簽字蓋章件',
  上传签字盖章件: '上傳簽字蓋章件',
  生成时间: '生成時間',
  打印次数: '列印次數',
  发送次数: '發送次數',
  保存并生成单据: '保存並生成單據',
  请选择单据模板: '請選擇單據模板',
  请上传签字盖章件: '請上傳簽字蓋章件',
  我方模板: '我方模板',
  对方模板: '對方模板',
  保存并生成单据成功: '保存並生成單據成功',
  订购须知默认:
    '<ul><li>請於{0}前確認本「委託訂票單」及辦理付款手續，如果未能在指定時間内回覆或未完成付款程序，本公司將不會為閣下提供訂票、送票服務，並保留取消此訂單之權利。</li><li>如受委託的訂票未能安排，所繳付之費用將全數退回。</li><li>若因時差問題，上述票款出現偏差，本公司將保留調整之權利。</li><li>在訂單已確認及作實後，如要更改或取消，須根據各產品的改期或退票條款處理，請查閱中旅社網網上所列各產品的「乘運條例」、「訂購細則」内容。</li><li>以上款項均以港幣計算。</li><li>送件時間：</li></ul><p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;11:00前確認，送件時間為當天14:00至18:00。</p><p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;11:00後至17:00前確認，送件時間為翌日9:00至13:00。</p><p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;如送票時段是星期六下午、星期日及公眾假期，送票時段會順延至該日期之後的第一個工作天的9：00至13：00</p><p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;如需更改送件時間，請於確認時通知我司。</p><ul><li>{1}</li><li>旅客於出發之前務必檢查證件及簽證之有效日期，以免造成延誤，影響行程！</li></ul>',
  发票号码: '發票號碼',
  收款原币金额: '收款原幣金額',
  本次对账原币金额: '本次對賬原幣金額',
  合计原币金额: '合計原幣金額',
  CC客户: 'CC客戶',
}
