export default {
  编辑: '編輯',
  修改: '修改',
  取消: '取消',
  新增: '新增',
  清空: '清空',
  '供应商名称：': '供應商名稱：',
  请输入供应商名称: '請輸入供應商名稱',
  客商名称: '客商名稱',
  '客商名称：': '客商名稱：',
  '权限：': '權限：',
  '合作范围：': '合作範圍：',
  '1.采购公司：供应商维护产品选择采购公司的范围，用于产品售卖后和供应商结算的公司主体。':
    '1.採購公司：供應商維護產品選擇採購公司的範圍，用於產品售賣後和供應商結算的公司主體。',
  '2.NBS部门：供应商维护产品选择中旅负责人的范围。':
    '2.NBS部門：供應商維護產品選擇中旅負責人的範圍。',
  采购公司: '採購公司',
  请输入采购公司: '請輸入採購公司',
  NBS部门: 'NBS部門',
  操作: '操作',
  删除: '删除',
  请选择客商: '請選擇客商',
  请选择级别: '請選擇級別',
  请选择采购公司: '請選擇採購公司',
  请选择部门: '請選擇部門',
  部门重复: '部門重複',
  请选择权限: '請選擇許可權',
  最少保留一个: '最少保留一個',
  编辑成功: '編輯成功',
  '账号：{0}，密码：{1}，请您牢记！': '賬號：{0}，密碼：{1}，請您牢記！',
  '新增客商已成功！': '新增客商已成功！',
  供应商名称: '供應商名稱',
  账号: '賬號',
  请输入账号: '請輸入賬號',
  账号不可重复: '賬號不可重複',
  状态: '狀態',
  请选择: '請選擇',
  查询: '査詢',
  更新时间: '更新時間',
  重置密码: '重置密碼',
  关闭: '關閉',
  开启: '開啟',
  操作记录: '操作記錄',
  是否确认重置密码: '是否確認重置密碼',
  提示: '提示',
  '密码重置成功!': '密碼重置成功！',
  '{0} 密码重置成功!</br>新密码：{1}，为保证数据安全，密码仅显示一次请您牢记！':
    '{0}密碼重置成功！</ br>新密碼：{1}，為保證資料安全，密碼僅顯示一次請您牢記！',
  '开启账号后，将恢复此账号权限，确定开启所选账号吗？':
    '開啟賬號後，將恢復此賬號許可權，確定開啟所選賬號嗎？',
  '关闭账号后，此账号权限将被收回，确定关闭所选账号吗？':
    '關閉賬號後，此賬號許可權將被收回，確定關閉所選賬號嗎？',
  账号开启成功: '賬號開啟成功',
  账号关闭成功: '賬號關閉成功',
  操作人: '操作人',
  操作账号: '操作賬號',
  操作时间: '操作時間',
  操作项: '操作項',
  暂无匹配数据: '暫無匹配數據',
  POI类型: 'POI類型',
  二级行政区划: '二級行政區劃',
  市: '市',
  编号: '編號',
  POI名称: 'POI名稱',
  请先选择POI类型: '請先選擇POI類型',
  新增POI: '新增POI',
  行政区划: '行政區劃',
  查看: '查看',
  保存: '保存',
  保存并新增: '保存並新增',
  保存并新增成功: '保存並新增成功',
  编辑POI: '編輯POI',
  查看POI: '查看POI',
  英文名: '英文名',
  简介: '簡介',
  图片: '圖片',
  详细地址: '詳細地址',
  公共交通: '公共交通',
  公交: '公車',
  地铁: '捷運',
  酒店级别: '酒店級別',
  酒店政策: '酒店政策',
  儿童政策: '兒童政策',
  宠物政策: '寵物政策',
  补充说明: '補充說明',
  入住时间: '入住時間',
  前: '前',
  离店时间: '退房時間',
  后: '後',
  联系电话: '聯繫電話',
  服务设施: '服務設施',
  景区级别: '景區級別',
  票价政策: '票價政策',
  开放时间: '開放時間',
  停止入场时间: '停止入場時間',
  景区热线: '景區熱線',
  著名景点: '著名景點',
  建议游玩时间: '建議遊玩時間',
  景点标签: '景點標籤',
  景点特色: '景點特色',
  推荐理由: '推薦理由',
  景点图片: '景點圖片',
  主营业务: '主營業務',
  营业时间: '營業時間',
  楼层导航: '樓層導航',
  至: '至',
  开始时间: '開始時間',
  结束时间: '結束時間',
  请选择大类: '請選擇大類',
  请选择小类: '請選擇小類',
  '上传图片小于2M，格式支持jpg、png、jpeg': '上傳圖片小於2M，格式支持jpg、png、jpeg',
  查看更多: '查看更多',
  查看图片: '查看圖片',
  本地上传: '本地上傳',
  '只支持上传 JPG/PNG/JPEG 格式图片': '只支持上傳JPG/PNG/JPEG格式圖片',
  '上传图片小于 2MB': '上傳圖片小於2MB',
  洲: '洲',
  国家: '國家',
  一级行政区: '一級行政區',
  二级行政区: '二級行政區',
  三级行政区: '三級行政區',
  行政区划前四级必填: '行政區劃前四級必填',
  '规范的POI名称应该是一个正式的全称或最常用的名称。':
    '規範的POI名稱應該是一個正式的全稱或最常用的名稱。',
  请输入名称: '請輸入名稱',
  请输入英文姓: '請輸入英文姓',
  请输入英文名: '請輸入英文名',
  '上传图片小于10M，格式支持jpg、png、jpeg': '上傳圖片小於10M，格式支持jpg、png、jpeg',
  请输入详细地址: '請輸入詳細地址',
  地铁只能输入数字和小数点: '只能輸入數字和小數點',
  请选择行政区划前四级: '請選擇行政區劃前四級',
  请选择POI类型: '請選擇POI類型',
  请输入POI名称: '請輸入POI名稱',
  亚洲: '亞洲',
  中国: '中國',
  最多上传10张图片: '最多上傳10張圖片',
  请输入儿童政策: '請輸入兒童政策',
  请输入宠物政策: '請輸入寵物政策',
  请输入补充说明: '請輸入補充說明',
  请选择入住时间: '請選擇入住時間',
  请选择离店时间: '請選擇退房時間',
  请输入联系电话: '請輸入聯繫電話',
  景点级别: '景點級別',
  请输入政策说明: '請輸入政策說明',
  请输入开放日期: '請輸入開放日期',
  请输入景区热线: '請輸入景區熱線',
  请输入著名景点: '請輸入著名景點',
  请输入建议游玩时间: '請輸入建議遊玩時間',
  请输入景点标签: '請輸入景點標籤',
  请输入景点特色: '請輸入景點特色',
  请输入景点推荐理由: '請輸入景點推薦理由',
  '请选择暴点相关的图片，图片尺寸宽度不小于': '請選擇暴點相關的圖片，圖片尺寸寬度不小於',
  '像素，大小不超过10M，数量不超过': '像素，大小不超過10M，數量不超過',
  '张。': '張。',
  目的地城市: '目的地城市',
  请输入: '請輸入',
  添加收款: '添加收款',
  添加退款: '添加退款',
  审核状态: '審核狀態',
  财务状态: '財務狀態',
  日结状态: '日結狀態',
  挂账金额: '掛賬金額',
  作废: '作廢',
  收款: '收款',
  退款: '退款',
  提交: '提交',
  确定作废收款: '確定作廢收款',
  确定作废退款: '確定作廢退款',
  '作废{0}后，原{1}会同步作废，如收据已打印/发送给客户，请务必跟客户沟通，收回{1}后再操作':
    '作廢{0}後，原{1}會同步作廢，如收據已打印/發送給客户，請務必跟客户溝通，收回{1}後再操作',
  作废成功: '作廢成功',
  订单金额: '訂單金額',
  待收总额: '待收總額',
  挂账待收总额: '掛賬待收總額',
  审批中金额: '審批中金額',
  刷新: '刷新',
  是否挂账还款: '是否掛賬還款',
  是: '是',
  否: '否',
  '挂账收款提交，会直接推送财务审核，财务审核通过后，系统自动根据收款金额返还挂账额度，另，此类收款数据，不会合并到销售日结表':
    '掛賬收款提交，會直接推送財務審核，財務審核通過後，系統自動根據收款金額返還掛賬額度，另，此類收款數據，不會合併到銷售日結錶',
  当前待收金额: '當前待收金額',
  收款方式: '收款方式',
  收款币种: '收款幣種',
  汇率: '匯率',
  本次使用金额: '本次使用金額',
  本次收款金额: '本次收款金額',
  附件: '附件',
  请输入正确的金额: '請輸入正確的金額',
  最多输入两位小数: '最多輸入兩位小數',
  请小于: '請小於',
  挂账核销金额: '掛賬核銷金額',
  挂账明细: '掛賬明細',
  挂账类型: '掛賬類型',
  挂账申请单号: '掛賬申請單號',
  销售点: '銷售點',
  挂账余额: '掛賬餘額',
  最晚收款时间: '最晚收款時間',
  最晚还款时间: '最晚還款時間',
  预收款明细: '預收款明細',
  押金明细: '押金明細',
  收款明细: '收款明細',
  卡机号码: '卡機號碼',
  '批号-参考号码': '批號-參考號碼',
  收款银行: '收款銀行',
  银行账号: '銀行賬號',
  客户银行: '客户銀行',
  商户号: '商戶號',
  交易流水号: '交易流水號',
  原币币种: '原幣幣種',
  预收单号: '預收單號',
  银行: '銀行',
  押金单号: '押金單號',
  当前待退金额: '當前待退金額',
  退款明细: '退款明細',
  可退款金额: '可退款金額',
  退款币种: '退款幣種',
  本次退款金额: '本次退款金額',
  上传文件: '上傳文件',
  已发起退款申请: '已發起退款申請',
  应收总额: '應收總額',
  收款详情: '收款詳情',
  退款详情: '退款詳情',
  退款金额: '退款金額',
  收款单号: '收款單號',
  退款单号: '退款單號',
  收款金额: '收款金額',
  退款方式: '退款方式',
  挂账使用金额: '掛賬使用金額',
  待收金额: '待收金額',
  本次挂账返还金额: '本次掛賬返還金額',
  请至少选择一条: '請至少選擇一條',
  请至少选择一个: '請至少選擇一個',
  收款提交成功: '收款提交成功',
  您可以选择以下方式将单据给到客户: '您可以選擇以下方式將單據給到客户',
  失败原因: '失敗原因',

  打印: '列印',
  通过电邮发送: '通過電郵發送',
  电邮地址: '電郵地址',
  单据明细: '單據明細',
  请选择要发送的单据: '請選擇要發送的單據',
  收据: '收據',
  发票: '發票',
  换票证: '換票證',
  电邮: '電郵',
  正确的电邮: '正確的電郵',
  发送: '發送',
  备注: '備註',
  详情: '詳情',
  收据编号: '收據編號',

  名称: '名稱',
  类型: '類型',
  行政区属: '行政區屬',
  添加: '添加',
  选择: '選擇',
  选择POI: '選擇POI',
  个景点: '個景點',
  最多可选择: '最多可選擇',
  景点: '景點',
  购物店: '購物店',
  酒店: '酒店',
  生成成功: '生成成功',
  下载模板: '下載模板',
  批量上传: '批量上傳',
  维护出游人: '維護出遊人',
  本单联络人: '本單聯絡人',
  登录账号: '登錄賬號',
  请输入登录账号: '請輸入登錄賬號',
  '请输入英文、数字和字符': '請輸入英文、數字和字符',
  证件号码: '證件號碼',
  证件类型: '證件類型',
  性别: '性別',
  会员常用旅客: '會員常用旅客',
  最近添加: '最近添加',
  港澳台居民居住证: '港澳臺居民居住證',
  可售余位: '可售餘位',
  预留库存: '預留庫存',
  需二次确认: '需二次確認',
  销售点无可售余位提示: '銷售點無可售餘位，請聯繫{0}協調庫存',
  共享: '共享',
  酒店名称: '酒店名稱',

  批量收款: '批量收款',
  对账状态: '對賬狀態',
  对账区间: '對賬區間',
  时间范围不得超过365天: '時間範圍不得超過365天',
  '输入对账单号，如果有多个，请以英文,进行隔开': '輸入對賬單號，如果有多個，請以英文, 進行隔開',
  对账单号不能全是空格: '對賬單號不能全是空格',
  汇总单号: '匯總單號',
  客户名称: '客戶名稱',
  收款人: '收款人',
  收款单状态: '收款單狀態',
  收款原币金额: '收款原幣金額',
  核销应付明细: '核銷應付明細',
  审核记录: '稽核記錄',
  '确定作废吗？作废成功后请去对账查询列表重新发起收款操作':
    '確定作廢嗎？ 作廢成功後請去對賬査詢清單重新發起收款操作',
  客户收款已作废: '客戶收款已作廢',
  客户收款已保存: '客戶收款已保存',
  客户收款已提交: '客戶收款已提交',
  对账单号: '對賬單號',
  交易时间: '交易時間',
  发票号码: '發票號碼',
  对账原币金额: '對賬原幣金額',
  剩余待收原币金额: '剩餘待收原幣金額',
  本次收款原币金额: '本次收款原幣金額',
  销售员: '銷售員',
  至少保留一条数据: '至少保留一條數據',
  '此操作将移除此挂账收款单不参与收款，确认操作吗？':
    '此操作將移除此掛賬收款單不參與收款，確認操作嗎？',
  审核节点: '稽核節點',
  审核人: '稽核人',
  审核意见: '稽核意見',
  审核时间: '稽核時間',
  业务单号: '業務單號',
  成本项: '成本項',
  应付原币金额: '應付原幣金額',
  未付原币金额: '未付原幣金額',
  本次核销原币金额: '本次核銷原幣金額',
  本次核销本币金额: '本次核銷本幣金額',
  业务日期: '業務日期',
  收款信息: '收款資訊',
  选择应付单: '選擇應付單',
  核销应付: '核銷應付',
  '已勾选{selectCount}个应付单，当前核销金额{collectionAmount}':
    '已勾選{selectCount}個應付單，當前核銷金額{collectionAmount}',
  请至少选择一条数据: '請至少選擇一條數據',
  提交收款: '提交收款',
}
