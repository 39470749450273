export default function (ajax, config) {
  return {
    // 枚举值公共接口
    enumValues: (opt) =>
      ajax({
        url: `/enum/values?name=${opt.name}`,
        method: 'GET',
        ...opt,
      }),
    // 销售日结报表
    salesReport: {
      // 获取收退款明细
      getReceiptData: (opt) =>
        ajax({
          url: '/daily-settlement/list-receipt-refund',
          method: 'post',
          ...opt,
        }),
      // 获取销售日结报表列表
      getSalesReportList: (opt) =>
        ajax({
          url: '/daily-settlement/list',
          method: 'post',
          ...opt,
        }),
      // 获取销售日结报表明细
      getSalesReportDetail: (opt) =>
        ajax({
          url: `/daily-settlement/${opt}`,
          method: 'get',
        }),
      // 获取销售日结报表明细(批量)
      getBatchSalesReportDetail: (opt) =>
        ajax({
          url: '/daily-settlement/audit-batch/detail',
          method: 'get',
          ...opt,
        }),
      // 添加
      addReport: (opt) =>
        ajax({
          url: '/daily-settlement/add',
          method: 'post',
          ...opt,
        }),
      //导出明细
      exportDetail: (opt) =>
        ajax({
          url: '/daily-settlement/export',
          method: 'post',
          file: true,
          responseType: 'blob',
          ...opt,
        }),
      // 保存销售日结报表
      saveReport: (opt) =>
        ajax({
          url: '/daily-settlement/save',
          method: 'post',
          data: opt,
        }),
      // 提交销售日结报表
      submitReport: (opt) =>
        ajax({
          url: '/daily-settlement/submit',
          method: 'post',
          data: opt,
        }),
      // 作废销售日结报表
      cancelReport: (opt) =>
        ajax({
          url: '/daily-settlement/cancel',
          method: 'post',
          data: opt,
        }),
      // 撤回销售日结报表
      resaveReport: (opt) =>
        ajax({
          url: '/daily-settlement/resave',
          method: 'post',
          data: opt,
        }),
      // 审核销售日结报表
      approveReport: (opt, batch) =>
        ajax({
          url: batch ? '/daily-settlement/audit-batch' : '/daily-settlement/audit',
          method: 'post',
          data: opt,
        }),
      // 实时生成文件
      getFile: (opt) =>
        ajax({
          url: `/daily-settlement/get-attachment/${opt}`,
          method: 'get',
        }),
      // 退回时刷新
      refresh: (opt) =>
        ajax({
          url: `/daily-settlement/refresh/${opt}`,
          method: 'get',
        }),
    },
    receipt: {
      // 获取预收款信息
      getPreReceiptsList: (opt) =>
        ajax({
          url: '/pre-receipt/list-all',
          method: 'get',
          data: opt,
        }),
    },
    refund: {
      // 可用收款单列表
      refundableList: (opt) =>
        ajax({
          url: '/receipt-by-order/available-receipts',
          method: 'post',
          ...opt,
        }),
    },
    // 收款方式
    getReceiptPayList: (opt) =>
      ajax({
        url: '/receipt-by-order/receipt-pay-type/list',
        method: 'post',
        ...opt,
      }),
    // 客户对账-收款
    receiptByBillCompare: {
      // 删除
      del: (opt) =>
        ajax({
          url: `/receipt-by-bill-compare/del/${opt.collectCode}`,
          method: 'GET',
        }),
      // 根据对账单号单个收款申请
      apply: (opt) =>
        ajax({
          url: '/receipt-by-bill-compare/apply',
          method: 'POST',
          ...opt,
        }),
      // 根据对账单号批量收款申请
      applyBatch: (opt) =>
        ajax({
          url: '/receipt-by-bill-compare/apply-batch',
          method: 'POST',
          ...opt,
        }),
      // 对账收款信息
      detail: (opt) =>
        ajax({
          url: `/receipt-by-bill-compare/detail/${opt.collectCode}`,
          method: 'GET',
        }),
      // 删除收款明细
      detailDel: (opt) =>
        ajax({
          url: `/receipt-by-bill-compare/detail-del/${opt.id}`,
          method: 'GET',
        }),
      // 收款明细-已经添加收款的明细
      detailPage: (opt) =>
        ajax({
          url: '/receipt-by-bill-compare/detail-page',
          method: 'POST',
          ...opt,
        }),
      // 收款明细更新金额
      detailUpdateAmount: (opt) =>
        ajax({
          url: '/receipt-by-bill-compare/detail-update-amount',
          method: 'POST',
          ...opt,
        }),
      // 查询对账收款方式-对账收款使用
      receiptModes: (opt) =>
        ajax({
          url: '/receipt-by-bill-compare/receipt-modes',
          method: 'POST',
          ...opt,
        }),
      // 清除核销应付明细
      subPaymentClear: (opt) =>
        ajax({
          url: `/receipt-by-bill-compare/sub-payment-clear/${opt.collectCode}`,
          method: 'GET',
        }),
      // 核销应付明细添加
      subPaymentAdd: (opt) =>
        ajax({
          url: '/receipt-by-bill-compare/sub-payment-add',
          method: 'POST',
          ...opt,
        }),
      // 核销应付明细删除
      subPaymentDel: (opt) =>
        ajax({
          url: `/receipt-by-bill-compare/sub-payment-del/${opt.id}`,
          method: 'GET',
        }),
      // 核销应付明细-已经添加
      subPaymentPage: (opt) =>
        ajax({
          url: '/receipt-by-bill-compare/sub-payment-page',
          method: 'POST',
          ...opt,
        }),
      // 核销应付明细-未添加的查询
      subPaymentPageNoAdd: (opt) =>
        ajax({
          url: '/receipt-by-bill-compare/sub-payment-page-no-add',
          method: 'POST',
          ...opt,
        }),
      // 核销应付明细更新金额
      subPaymentUpdateAmount: (opt) =>
        ajax({
          url: '/receipt-by-bill-compare/sub-payment-update-amount',
          method: 'POST',
          ...opt,
        }),
      // 作废
      invalid: (opt) =>
        ajax({
          url: `/receipt-by-bill-compare/invalid/${opt.collectCode}`,
          method: 'GET',
        }),
      // 保存
      save: (opt) =>
        ajax({
          url: '/receipt-by-bill-compare/save',
          method: 'POST',
          ...opt,
        }),
      // 提交收款
      submitOrUpdate: (opt) =>
        ajax({
          url: '/receipt-by-bill-compare/submit-or-update',
          method: 'POST',
          ...opt,
        }),
    },
  }
}
